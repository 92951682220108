import { MenuItemLink, useTranslate, useSidebarState, usePermissions, useDataProvider } from 'react-admin';
import UserIcon from '@mui/icons-material/Group';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SettingsIcon from '@mui/icons-material/Settings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StoreIcon from '@mui/icons-material/Store';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SubMenu from './common_modules/components/SubMenu';
import BusinessIcon from '@mui/icons-material/Business';
import LinkIcon from '@mui/icons-material/Link';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ROLE_ADMIN, ROLE_EDIT, ROLE_SALES } from './common_modules/constants/define';
import { hasPermission } from './authProvider';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

interface CustomMenuProps {
  dense?: boolean;
}

type MenuName = 'menuSettings' | 'menuAnalytics';

const CustomMenu = ({ dense = false }: CustomMenuProps) => {
  const [state, setState] = useState({
    menuSettings: true,
    menuAnalytics: true,
  });
  const navigate = useNavigate();
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [newClientCount, setNewClientCount] = useState(0);
  const dataProvider = useDataProvider();

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };
  // const handleSubMenuClick = () => {
  //   navigate('/analytics');
  //   handleToggle('menuAnalytics');
  // };
  const { permissions } = usePermissions();

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataProvider.getList('clients', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: { applyStatus: 1 }
      });
      const total = data?.total ?? 0;
      if (total > 0) {
        setNewClientCount(total);
      } else {
        setNewClientCount(0);
      }
    };
    fetchData();
  }, [dataProvider]);

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        height: 'calc(100vh - env(safe-area-inset-bottom))',
        marginTop: 1,
        marginBottom: 1,
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch',
        transition: theme =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {hasPermission(permissions, ROLE_ADMIN) &&
        <MenuItemLink
          to="/admin-users"
          primaryText={translate(`resources.admin-users.name`)}
          leftIcon={<UserIcon />}
          dense={dense}
        />
      }

      {hasPermission(permissions, ROLE_EDIT) &&
        <MenuItemLink
          to="/clients"
          primaryText={translate(`resources.clients.name`)}
          leftIcon={<StoreIcon />}
          dense={dense}>
          {newClientCount > 0 && (
            <Badge badgeContent={newClientCount} color="primary" sx={{ '& .MuiBadge-badge': { right: '-8px' } }}>
              {translate(`resources.clients.name`)}
            </Badge>
          )}
        </MenuItemLink>
      }

      {hasPermission(permissions, ROLE_EDIT) &&
        <MenuItemLink
          to="/partners"
          primaryText={translate(`resources.partners.name`)}
          leftIcon={<HandshakeIcon />}
          dense={dense} />
      }


      <SubMenu
        handleToggle={() => handleToggle('menuAnalytics')}
        isOpen={state.menuAnalytics}
        name="resources.analytics.name.analytics"
        icon={<LeaderboardIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/all-analytics"
          primaryText={translate(`resources.analytics.name.all`)}
          leftIcon={<LeaderboardIcon />}
          dense={dense} />
        <MenuItemLink
          to="/clients-analytics"
          primaryText={translate(`resources.analytics.name.clients`)}
          leftIcon={<LeaderboardIcon />}
          dense={dense} />
        <MenuItemLink
          to="/partners-analytics"
          primaryText={translate(`resources.analytics.name.partners`)}
          leftIcon={<LeaderboardIcon />}
          dense={dense} />
      </SubMenu>

      {hasPermission(permissions, ROLE_EDIT) &&
        <MenuItemLink
          to="/orders"
          primaryText={translate(`resources.orders.name`)}
          leftIcon={<ShoppingCartIcon />}
          dense={dense} />
      }

      {hasPermission(permissions, ROLE_SALES) &&
        <MenuItemLink
          to="/invoices"
          primaryText={translate(`resources.invoices.name`)}
          leftIcon={<MonetizationOnIcon />}
          dense={dense} />
      }

      {hasPermission(permissions, ROLE_SALES) &&
        <MenuItemLink
          to="/payments"
          primaryText={translate(`resources.payments.name`)}
          leftIcon={<PaymentsIcon />}
          dense={dense} />
      }
      {hasPermission(permissions, ROLE_ADMIN) &&
        <SubMenu
          handleToggle={() => handleToggle('menuSettings')}
          isOpen={state.menuSettings}
          name="resources.settings.name"
          icon={<SettingsIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/company-settings"
            primaryText={translate(`resources.company-settings.name`)}
            leftIcon={<BusinessIcon />}
            dense={dense} />

          <MenuItemLink
            to="/transfer-settings"
            primaryText={translate(`resources.transfer-settings.name`)}
            leftIcon={<AccountBalanceIcon />}
            dense={dense} />

          <MenuItemLink
            to="/payment-settings"
            primaryText={translate(`resources.payment-settings.name`)}
            leftIcon={<AttachMoneyIcon />}
            dense={dense} />

          <MenuItemLink
            to="/other-settings"
            primaryText={translate(`resources.other-settings.name`)}
            leftIcon={<MiscellaneousServicesIcon />}
            dense={dense} />
        </SubMenu>
      }
      {hasPermission(permissions, ROLE_EDIT) &&
        <MenuItemLink
          to="/invite-url"
          primaryText={translate(`resources.invite-url.name`)}
          leftIcon={<LinkIcon />}
          dense={dense} />
      }
    </Box>
  );
};


export default CustomMenu;
