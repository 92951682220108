import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import dataProvider from '../dataProvider';
import { Title, useTranslate } from 'react-admin';

const ClientInviteUrl = () => {
  const [inviteUrl, setInviteUrl] = useState<string | undefined>(undefined);
  const [qrCode, setQrCode] = useState<string | undefined>(undefined);
  const translate = useTranslate();
  useEffect(() => {
    const fetchData = async () => {
      const response = await dataProvider.getData('invite-url');
      setInviteUrl(response.clientInviteUrl);
      setQrCode(response.qrCode); // APIからのQRコードをセット
    };

    fetchData();
  }, []);

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (window.confirm('このリンクを新しいタブで開きますか？')) {
      window.open(inviteUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Card>
      <Title title={translate('resources.invite-url.name')} />
      <CardContent>
        <Typography variant="body1">以下のURL、QRコードを使用して新規ECサイトを招待してください。</Typography>
        <Box display="flex" flexDirection="column" justifyContent="flex-start" mt={2}>
          <Typography variant="caption" color="textSecondary">ECサイト招待URL</Typography>
          <Typography variant="body2">
            <a href={inviteUrl} onClick={handleLinkClick} style={{ cursor: 'pointer' }}>
              {inviteUrl}
            </a>
          </Typography>
          <Box mt={2}>
            <Typography variant="caption" color="textSecondary">QRコード</Typography>
            <Box mt={1}>
              <img src={qrCode} alt="QR Code" style={{ maxWidth: '100px' }} />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ClientInviteUrl;