import simpleRestProvider from 'ra-data-simple-rest';
import { DataProvider, Options, fetchUtils } from 'react-admin';
import fileDownload from 'js-file-download';

const API_URL = import.meta.env.VITE_SIMPLE_REST_URL;

const getRequestOptions = (method: string, body?: any) => {
  const token = localStorage.getItem('token');
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  });

  // eslint-disable-next-line no-undef
  const options: RequestInit = {
    method,
    headers: headers
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  return options;
};

const httpClient = (url: any, options: Options | undefined = {}) => {
  const token = localStorage.getItem('token') ?? undefined;
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };

  return fetchUtils.fetchJson(url, options);
};

export const getMeData = async () => {
  const response = await fetch(import.meta.env.VITE_SIMPLE_REST_URL + '/me', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (!response.ok) {
    throw {
      code: 'MeEndpointException'
    };
  }
  return await response.json();
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of dataprovider decoration.
 */
const convertFileToBase64 = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const convertFilesInObjectToBase64 = async (obj: any): Promise<any> => {
  if (obj !== null && typeof obj === 'object') {
    const entries = Object.entries(obj);
    const result: any = {};
    for (const [key, value] of entries) {
      if (value && typeof value === 'object') {
        if ('rawFile' in value && value.rawFile instanceof File) {
          const base64 = await convertFileToBase64(value.rawFile);
          result[key] = base64;
        } else if ('src' in value && (value.src == null || typeof value.src === 'string')) {
          result[key] = value.src;
        } else {
          result[key] = value;
        }
      } else {
        result[key] = value;
      }
    }
    return result;
  }

  return obj;
};

export const dataProvider: DataProvider = {
  ...simpleRestProvider(API_URL, httpClient),

  create: async (resource, params) => {
    const dataWithBase64 = await convertFilesInObjectToBase64(params.data);
    return simpleRestProvider(API_URL, httpClient).create(resource, {
      ...params,
      data: dataWithBase64
    });
  },

  update: async (resource, params) => {
    const dataWithBase64 = await convertFilesInObjectToBase64(params.data);
    return simpleRestProvider(API_URL, httpClient).update(resource, {
      ...params,
      data: dataWithBase64
    });
  },

  download: async (url: string) => {
    const token = localStorage.getItem('token') ?? undefined;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/octet-stream',
        Authorization: `Bearer ${token}`
      })
    });

    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = 'untitled';
    if (contentDisposition && contentDisposition.includes('filename=')) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch && fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }

    const blob = await response.blob();
    fileDownload(blob, fileName);
  },

  getReportData: async (startDate: string, endDate: string, dateType: number, resultType: number) => {
    try {
      const url = `${API_URL}/reports?startDate=${startDate}&endDate=${endDate}&dateType=${dateType}&resultType=${resultType}`;
      const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
      return response.json;
    } catch (error) {
      throw error;
    }
  },

  getData: async (resource: string) => {
    const url = `${API_URL}/${resource}`;
    const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
    return response.json;
  },

  register: async (data: Record<string, any>, url: string, method: string = 'POST') => {
    const dataWithBase64 = method === 'POST' ? await convertFilesInObjectToBase64(data) : data;
    const absoluteUrl = new URL(url, API_URL).toString();
    return httpClient(absoluteUrl, {
      method,
      body: method === 'POST' ? JSON.stringify(dataWithBase64) : undefined
    }).then(({ json }) => ({
      data: json
    }));
  }
};

export default dataProvider;
