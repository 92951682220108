import messages from '@bicstone/ra-language-japanese';

export const japaneseMessages = {
  ...messages,
  Role: '【FEEBACK管理者】',
  resources: {
    'admin-users': {
      name: 'スタッフ管理',
      fields: {
        id: '管理者ID',
        name: '名前',
        email: 'メールアドレス',
        authority: '権限',
        accountInvalidFlag: 'アカウント有効フラグ',
        clientManagementType: '顧客管理',
        lastLoginDate: '最終利用日時',
        cognitoId: 'Cognito ID'
      }
    },
    clients: {
      name: 'ECサイト管理',
      fields: {
        id: '顧客ID',
        name: '登録名',
        joinDate: '登録日付',
        email: 'メールアドレス',
        tel: '電話番号',
        zipCode: '郵便番号',
        prefecture: '都道府県',
        city: '市区町村',
        address1: '番地',
        address2: 'ビル名 部屋番号',
        bankNumber: '銀行番号',
        bankName: '銀行名',
        bankBranchNumber: '支店番号',
        bankBranchName: '支店名',
        bankAccountType: '口座種類',
        bankAccountNumber: '口座番号',
        bankAccountName: '口座名義',
        accountInvalidFlag: 'アカウント有効フラグ',
        ecSiteUrl: 'ECサイトURL',
        ecSiteType: 'ECサイトタイプ',
        closingDate: '締め日',
        invoiceDate: '請求日',
        plannedPaymentMonth: '入金予定月',
        plannedPaymentDate: '入金予定日',
        feeSystemReward: 'FEEBACK手数料',
        introducerReward: '紹介報酬',
        partnerReward: 'パートナー報酬',
        systemUsageFee: 'システム利用料金',
        currentFeeSystemReward: '現在のFEEBACK側手数料',
        currentIntroducerReward: '現在の紹介者の報酬',
        currentPartnerReward: '現在のパートナーの報酬',
        operationStartDate: '適用開始日',
        applyStatus: '申請状況',
        applyStatusSelect: '申請可否'
      }
    },
    'default-rewards': {
      name: 'デフォルト報酬設定',
      fields: {
        id: 'デフォルト報酬設定ID',
        clientName: '登録名',
        feeSystemReward: 'FEEBACK手数料',
        introducerReward: '紹介者の報酬',
        partnerReward: 'パートナーの報酬',
        operationStartDate: '適用開始日'
      }
    },
    partners: {
      name: 'パートナー管理',
      fields: {
        id: 'パートナーID',
        partnerType: 'アカウント種別',
        name: 'パートナー登録名',
        lastName: '姓',
        picLastName: 'ご担当者姓',
        firstName: '名',
        picFirstName: 'ご担当者名',
        zipCode: '郵便番号',
        address: '住所',
        prefecture: '都道府県',
        city: '市区町村',
        address1: '番地',
        address2: 'ビル名 部屋番号',
        tel: '電話番号',
        mobilePhone: '携帯電話番号',
        email: 'メールアドレス',
        bankNumber: '銀行番号',
        bankName: '銀行名',
        bankBranchNumber: '支店番号',
        bankBranchName: '支店名',
        bankAccountType: '口座種類',
        bankAccountNumber: '口座番号',
        bankAccountName: '口座名義',
        invoiceNumber: 'インボイス登録番号',
        dunsNumber: 'D-U-N-S番号',
        parentPartner: '紹介元パートナー',
        website: 'Webサイト',
        frontImage: '身分証明書 表画像',
        backImage: '身分証明書 裏画像',
        accountInvalidFlag: 'アカウント有効フラグ',
        joinDate: '登録日付'
      }
    },
    analytics: {
      name: {
        analytics: 'アナリティクス',
        all: '全体',
        clients: 'ECサイト別',
        partners: 'パートナー別'
      },
      fields: {
        totalSalesAmount: 'FEEBACK経由総売上',
        totalFeebackCommission: '手数料収益',
        totalPartnerCommission: 'パートナー報酬支払額',
        totalIntroducerCommission: '紹介報酬額',
        totalAffiliateCommission: 'パートナー報酬支払額',
        totalFeebackClaim: 'FEEBACK売上',
        totalSalesCount: '合計売上件数',
        totalClickCount: '合計訪問数',
        startDate: '開始日',
        endDate: '終了日',
        selectClient: 'ECサイト選択',
        selectPartner: 'パートナー選択',
        granularity: '粒度',
        graphTarget: 'グラフ対象選択',
        resultType: '内訳対象選択',
        date: '期間',
        details: '売上と件数の内訳',
        downloadCSV: 'CSVダウンロード',
        salesAmount: '売上高',
        salesAmountRate: '売上割合',
        adjustedSalesAmount: '売上高',
        feebackCommission: '手数料収益',
        partnerCommission: 'パートナー報酬支払額',
        introducerCommission: '紹介報酬額',
        affiliateCommission: 'パートナー報酬支払額',
        salesCount: '売上件数',
        clickCount: '訪問数'
      }
    },
    orders: {
      name: '注文管理',
      fields: {
        clientId: 'ECサイト',
        orderId: 'ECサイト注文ID',
        ecSiteClientId: 'ECサイト顧客ID',
        affiliateId: 'アフィリエイトID',
        orderDate: '注文日時',
        price: '価格(税抜)',
        isCancelled: 'キャンセル'
      }
    },
    invoices: {
      name: '売上請求管理',
      detail: '詳細',
      fields: {
        issueDate: '発行日',
        invoiceNumber: '請求番号',
        clientId: '顧客ID',
        clientName: '登録名',
        salesStartDate: '売上発生開始日時',
        salesEndDate: '請求',
        subTotal: '小計',
        salesTax: '消費税',
        totalAmount: '合計金額',
        paymentDeadline: '振込期日',
        status: '入金ステータス',
        depositDate: '入金日',
        invoiceMemo: '請求備考',
        depositMemo: '入金備考',
        invoicePdf: '請求書',
        receiptPdf: '領収書',
        invoiceLink: '請求書リンク',
        receiptLink: '領収書リンク',
        invoiceDetailPdf: '明細をダウンロード'
      }
    },
    payments: {
      name: '支払い管理',
      detail: '詳細',
      fields: {
        paymentDate: '支払日',
        totalAmountWithoutTax: '支払い金額（税抜）',
        totalTax: '消費税',
        totalAmount: '支払い金額（税込）',
        paymentGroupStatus: '支払いステータス',
        memo: '備考',
        zenginUrl: '全銀フォーマットのダウンロードURL',
        zenginFile: '全銀フォーマット',
        paymentStatus: '支払いステータス'
      }
    },
    'invite-url': {
      name: 'ECサイト招待URL',
      fields: {
        clientInvteUrl: 'ECサイト招待URL'
      }
    },
    settings: {
      name: '設定'
    },
    'company-settings': {
      name: '会社設定',
      fields: {
        companyName: '社名',
        companyZip: '郵便番号',
        companyAddress: '住所',
        companyAddress2: 'ビル名・部屋番号',
        companyTel: '電話番号',
        companyFax: 'FAX番号',
        companyInvoice: 'インボイス登録番号'
      }
    },
    'transfer-settings': {
      name: '振込設定',
      fields: {
        remitterCode: '振込依頼人コード',
        remitterName: '振込依頼人名',
        bankNumber: '銀行番号',
        bankName: '銀行名',
        bankBranchNumber: '支店番号',
        bankBranchName: '支店名',
        accountType: '預金種別',
        accountNumber: '口座番号',
        closingDate: '締め日',
        memo: '備考'
      }
    },
    'payment-settings': {
      name: '支払い設定',
      fields: {
        paymentDueDate: '支払い締め日',
        plannedPaymentMonth: '支払い予定月',
        plannedPaymentDate: '支払い予定日',
        minimumPaymentAmount: '最低報酬支払い金額',
        administrativeFee: '振込事務手数料'
      }
    },
    'other-settings': {
      name: 'デフォルト設定',
      fields: {
        defaultSystemUsageFee: 'システム利用料金',
        defaultFeeSystemReward: 'FEEBACK手数料',
        defaultPartnerReward: 'パートナー手数料'
      }
    },
    registers: {
      name: {
        Terms: '利用規約及び契約内容',
        Form: '必要事項入力',
        Confirmation: '入力内容確認',
        Completion: '登録完了',
        Conditions: '利用規約',
        Contracts: '契約内容',
        CreateStaff: 'パスワード登録',
        Upload: '身分証明書提出',
        SMSAuth: 'SMS認証'
      },
      fields: {
        partnerType: 'アカウント種別',
        name: '名前',
        lastName: '姓',
        picLastName: 'ご担当者姓',
        firstName: '名',
        picFirstName: 'ご担当者名',
        zipCode: '郵便番号',
        address: '住所',
        prefecture: '都道府県',
        city: '市区町村',
        address1: '番地',
        address2: 'ビル名 部屋番号',
        tel: '電話番号',
        email: 'メールアドレス',
        bankNumber: '銀行番号',
        bankName: '銀行名',
        bankBranchNumber: '支店番号',
        bankBranchName: '支店名',
        bankAccountType: '口座種類',
        bankAccountNumber: '口座番号',
        bankAccountName: '口座名義',
        invoiceNumber: 'インボイス登録番号',
        dunsNumber: 'D-U-N-S番号',
        website: 'Webサイト',
        frontImage: '身分証明書 表画像',
        backImage: '身分証明書 裏画像',
        userName: '初期ユーザー名',
        userEmail: '初期ユーザーのメールアドレス',
        userPassword: 'パスワード',
        userPasswordConfirm: 'パスワード確認',
        confirmationCode: '認証番号'
      }
    }
  },
  values: {
    authority: {
      1: '管理者',
      2: 'マネージャー',
      3: '経理'
    },
    partnerType: {
      1: '個人',
      2: '法人'
    },
    accountStatus: {
      0: '無効',
      1: '有効',
      2: '仮登録'
    },
    accountInvalidFlag: {
      0: '無効',
      1: '有効',
      2: '仮登録'
    },
    accountInvalidFlagWithoutTemporaryregistration: {
      0: '無効',
      1: '有効'
    },
    clientManagementType: {
      1: '全て',
      2: '個別管理'
    },
    ecSiteType: {
      1: 'Shopify'
      // 99: 'その他'
    },
    bankAccountType: {
      1: '普通',
      2: '当座'
    },
    paymentMonth: {
      0: '当月',
      1: '翌月',
      2: '翌々月'
    },
    paymentDate: '%{day}日',
    paymentDateEndOfMonth: '月末',
    'apply-status': {
      1: '未承認',
      2: '申請済み',
      3: '却下'
    },
    depositStatus: {
      1: '未入金',
      2: '入金済み'
    },
    invalidFlag: {
      0: '無効',
      1: '有効'
    },
    isCancelled: {
      true: 'キャンセル',
      false: ''
    },
    paymentStatus: {
      1: '支払い不要',
      2: '未振込',
      3: '振込済み',
      4: 'エラー'
    },
    applyStatus: {
      1: '未承認',
      2: '承認済み',
      3: '却下'
    },
    resultTypes: {
      1: '全体',
      2: 'ECストア',
      3: 'パートナー'
      // 4: 'アフィリエイト'
    },
    graphTargetTypes: {
      salesAmount: 'FEEBACK経由総売上',
      feebackCommission: '手数料収益',
      partnerCommission: 'パートナー報酬支払額',
      introducerCommission: '紹介報酬額',
      affiliateCommission: 'パートナー報酬支払額',
      salesCount: '売上件数',
      clickCount: '訪問数'
    },
    graphTargetUnits: {
      salesAmount: '円',
      feebackCommission: '円',
      partnerCommission: '円',
      introducerCommission: '円',
      affiliateCommission: '円',
      salesCount: '件',
      clickCount: '回'
    }
  },
  common: {
    none: 'なし'
  },
  cognito: {
    Unknown: 'サーバーとの通信に失敗しました。しばらくしてから再度お試しください。',
    MeEndpointException: 'ユーザーの取得に失敗しました。現在アカウントが利用できません。',
    NotAuthorizedException: 'ユーザー名もしくはパスワードが間違っています。',
    UserNotFoundException: 'ユーザーが見つかりません。',
    CodeMismatchException: '認証コードが間違っています。',
    UsernameExistsException: 'すでに同じユーザーが登録されています。',
    InvalidPasswordException:
      'パスワードは大文字、小文字、数字、英字をそれぞれ1文字以上含む8文字以上で設定してください。',
    ExpiredCodeException: '認証コードの有効期限が切れています。',
    LimitExceededException: 'リクエストが制限を超えました。しばらく時間をおいてから再度お試しください。'
  }
};
