import { SelectInput, required, useDataProvider, Form } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import React from 'react';

interface InvoiceMonthSelectorProps {
  // eslint-disable-next-line no-unused-vars
  onDateChange: (id: string) => void;
  onEmptyData: () => void;
}

const InvoiceMonthSelector: React.FC<InvoiceMonthSelectorProps> = (props) => {
  const dataProvider = useDataProvider();
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [defaultSelectedDate, setDefaultSelectedDate] = useState(undefined);

  const [totalAmount, setTotalAmount] = useState(0);
  const [invoiceDateString, setInvoiceDateString] = useState('');

  const handleDateChange = (event: any) => {
    setSelectedDate(event.target.value);
    props.onDateChange(event.target.value)
  };


  useEffect(() => {
    const getInvoiceDateList = async () => {
      const url = `invoice-date-list`;
      return await dataProvider.getData(url);
    }

    const fetchDates = async () => {
      const result = await getInvoiceDateList();
      const dateList = result.map((date: string) => {
        const d = new Date(date);
        const dateName = `${d.getFullYear()}年${d.getMonth() + 1}月`;
        return { id: date.slice(0, 10), name: dateName };
      });
      setDates(dateList);
      if (dateList.length > 0 && selectedDate === '') {
        setSelectedDate(dateList[0].id)
        props.onDateChange(dateList[0].id)
        setDefaultSelectedDate(dateList[0].id)
      }

      if (dateList.length === 0) {
        props.onEmptyData();
      }
    };
    fetchDates();
  }, [dataProvider, selectedDate, props, setDefaultSelectedDate]);

  useEffect(() => {
    const getInvoiceTotal = async (dateString: string) => {
      const url = `invoice-total/${dateString}`;
      return await dataProvider.getData(url);
    }

    const fetchTotalAmount = async () => {
      if (selectedDate) {
        const data = await getInvoiceTotal(selectedDate);

        const d = new Date(selectedDate);
        const dateName = `${d.getFullYear()}年${d.getMonth() + 1}月`;
        setInvoiceDateString(dateName);
        setTotalAmount(data.totalAmountSum || 0);
      }
    };
    fetchTotalAmount();
  }, [selectedDate, dataProvider]);

  return (
    <Box sx={{ mt: 4, ml: 1, mb: 2 }}>
      <Box>
        {defaultSelectedDate && (
          <Form {...props}>
            <SelectInput label="請求月" source="month" choices={dates}
              validate={required()}
              onChange={handleDateChange}
              value={selectedDate}
              defaultValue={defaultSelectedDate}
              alwaysOn />
          </Form>
        )}
      </Box>
      {invoiceDateString.length > 0 && (
        <Box>
          <Typography variant="body1">{invoiceDateString}</Typography>
          <Typography variant="h6">合計請求金額: {totalAmount.toLocaleString()}円</Typography>
        </Box>
      )}

    </Box>
  );
};

export default InvoiceMonthSelector;

