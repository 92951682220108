import React, { useEffect, useState } from 'react';
import {
  Create,
  CreateButton,
  DeleteButton,
  DateField,
  Edit,
  EditButton,
  SaveButton,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  Toolbar,
  TextInput,
  TopToolbar,
  required,
  useNotify,
  useRedirect,
  useTranslate,
  useRecordContext,
} from 'react-admin';
import { Box } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { ACCOUNT_STATUS, AUTHORITY_CHOICES } from '../common_modules/constants/choices';
import EnumTextField from '../common_modules/components/EnumTextField';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import FormValidation from '../common_modules/components/FormValidation';

const ACCOUNT_STATUS_EDIT = ACCOUNT_STATUS.filter((status) => status.id !== 2);

const CustomCreateButton = () => <CreateButton label="スタッフ追加" icon={<PersonAddAlt1Icon />} />;

const ListActions = () => (
  <TopToolbar>
    <CustomCreateButton />
  </TopToolbar>
);

const CustomDeleteButton = () => {
  const record = useRecordContext();

  return <DeleteButton confirmTitle={`${record?.name} を削除`} confirmContent="本当に削除しますか？" />;
};

// CustomCreateToolbar を FormValidation でラップする
const CustomCreateToolbar = ({ isFormValid }: { isFormValid: boolean }) => (
  <Toolbar>
    <SaveButton disabled={!isFormValid} label="登録" icon={<MailOutlineIcon />} />
  </Toolbar>
);

// CustomEditToolbar を FormValidation でラップする
const CustomEditToolbar = ({ isFormValid }: { isFormValid: boolean }) => (
  <Toolbar>
    <SaveButton disabled={!isFormValid} />
    <Box flex="1" />
    <CustomDeleteButton />
  </Toolbar>
);

export const AdminUserList = () => {
  const translate = useTranslate();
  return (
    <List exporter={false} actions={<ListActions />}>
      <FlexibleListLayout rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label={translate('resources.admin-users.fields.name')} />
        <TextField source="email" label={translate('resources.admin-users.fields.email')} />
        <EnumTextField source="authority" translationKeyPrefix={'values.authority'} label={translate('resources.admin-users.fields.authority')} />
        <EnumTextField source="accountInvalidFlag" translationKeyPrefix={'values.accountInvalidFlag'} />
        <DateField showTime={true} source="lastLoginDate" />
        <EditButton />
      </FlexibleListLayout>
    </List>
  );
};


interface ConditionalFieldProps {
  source: string;
  choices: Array<{ id: number; name: string }>;
  validate?: any;
}
const ConditionalNumericRadioButtonGroupInput = ({ source, choices, validate }: ConditionalFieldProps) => {
  const record = useRecordContext();
  return <NumericRadioButtonGroupInput source={source} choices={choices} disabled={record?.accountInvalidFlag === 2} validate={validate} />;
};

// AdminUserEdit コンポーネント
export const AdminUserEdit = () => {
  const handleError = useErrorHandler();
  const requiredFields = ['name', 'authority', 'accountInvalidFlag']; // 必須フィールドのリスト

  return (
    <Edit mutationMode="pessimistic" mutationOptions={{ onError: handleError }}>
      <SimpleForm
        toolbar={
          <FormValidation requiredFields={requiredFields}>
            {(isFormValid) => <CustomEditToolbar isFormValid={isFormValid} />}
          </FormValidation>
        }
        sx={{ maxWidth: '600px' }}
      >
        <TextInput source="name" fullWidth validate={required()} />
        <SimpleShowLayout sx={{ paddingLeft: '0' }}>
          <TextField source="email" />
        </SimpleShowLayout>
        <NumericRadioButtonGroupInput source="authority" choices={AUTHORITY_CHOICES} validate={required()} />
        <ConditionalNumericRadioButtonGroupInput source="accountInvalidFlag" choices={ACCOUNT_STATUS_EDIT} validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

// AdminUserCreate コンポーネント
export const AdminUserCreate = () => {
  const handleError = useErrorHandler();
  const notify = useNotify();
  const redirect = useRedirect();
  const requiredFields = ['name', 'email', 'authority']; // 必須フィールドのリスト

  return (
    <Create
      title="スタッフ追加"
      mutationOptions={{
        onSuccess: (data: any) => {
          notify(`${data.name}を登録しました`, { type: 'info' });
          redirect('list', 'admin-users');
        },
        onError: handleError
      }}
    >
      <SimpleForm
        toolbar={
          <FormValidation requiredFields={requiredFields}>
            {(isFormValid) => <CustomCreateToolbar isFormValid={isFormValid} />}
          </FormValidation>
        }
        sx={{ maxWidth: '600px' }}
      >
        <TextInput source="name" fullWidth validate={required()} />
        <TextInput source="email" fullWidth validate={required()} />
        <NumericRadioButtonGroupInput source="authority" choices={AUTHORITY_CHOICES} validate={required()} />
      </SimpleForm>
    </Create>
  );
};

// AdminUserShow コンポーネント
export const AdminUserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="email" />
      <EnumTextField source="authority" translationKeyPrefix="values.authority" />
      <EnumTextField source="accountInvalidFlag" translationKeyPrefix="values.accountInvalidFlag" />
      <DateField showTime={true} source="lastLoginDate" />
    </SimpleShowLayout>
  </Show>
);
