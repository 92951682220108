import { useEffect } from 'react';
import { usePermissions, useRedirect } from 'react-admin';
import { hasPermission } from '../authProvider';
import { ROLE_ADMIN, ROLE_EDIT, ROLE_SALES } from '../common_modules/constants/define';
import {
  Box,
} from '@mui/material';

export const Dashboard = () => {
  const { isLoading, permissions } = usePermissions();
  const redirect = useRedirect();

  useEffect(() => {
    if (!isLoading) {
      if (hasPermission(permissions, ROLE_SALES)) {
        redirect('/all-analytics');
      }
      if (hasPermission(permissions, ROLE_EDIT)) {
        redirect('/all-analytics');
      }
      if (hasPermission(permissions, ROLE_ADMIN)) {
        redirect('/all-analytics');
      }
    }
  }, [isLoading, permissions, redirect]);


  return (<Box />);
};

export default Dashboard;
