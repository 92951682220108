import {
  Edit, NumberInput, required, SimpleForm
} from 'react-admin';
import useErrorHandler from '../../common_modules/hooks/useErrorHandler';
import SaveOnlyToolbar from '../../common_modules/components/SaveOnlyToolbar';
import PercentNumberInput from '../../common_modules/components/PercentNumberInput';
import { InputAdornment } from '@mui/material';

export const OtherSettingsEdit = () => {
  const handleError = useErrorHandler();
  return (
    <Edit title="デフォルト設定" mutationMode='pessimistic' mutationOptions={{ onError: handleError }} resource='other-settings' id={1} redirect={false}>
      <SimpleForm toolbar={<SaveOnlyToolbar />} sx={{ maxWidth: '600px' }}>
        <NumberInput source="defaultSystemUsageFee" validate={required()}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
        />
        <PercentNumberInput source="defaultFeeSystemReward" validate={required()} />
        {/*defaultPartnerRewardは0.00に設定  */}
      </SimpleForm>
    </Edit>
  );
};

export default OtherSettingsEdit;
