import {
  Edit, SimpleForm, SelectInput,
  NumberInput,
  required
} from 'react-admin';
import { CLOSING_DATE, CLOSING_MONTH } from '../../common_modules/constants/choices';
import NumericRadioButtonGroupInput from '../../common_modules/components/NumericRadioButtonGroupInput';
import useErrorHandler from '../../common_modules/hooks/useErrorHandler';
import SaveOnlyToolbar from '../../common_modules/components/SaveOnlyToolbar';
import { InputAdornment } from '@mui/material';

export const PaymentSettingsEdit = () => {
  const handleError = useErrorHandler();
  return (
    <Edit title="支払い設定" mutationMode='pessimistic' mutationOptions={{ onError: handleError }} resource='payment-settings' id={1} redirect={false}>
      <SimpleForm toolbar={<SaveOnlyToolbar />} sx={{ maxWidth: '600px' }}>
        <SelectInput source="paymentDueDate" choices={CLOSING_DATE} validate={required()} />
        <NumericRadioButtonGroupInput source="plannedPaymentMonth" choices={CLOSING_MONTH} validate={required()} />
        <SelectInput source="plannedPaymentDate" choices={CLOSING_DATE} validate={required()} />
        <NumberInput source="minimumPaymentAmount" validate={required()}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
        />
        <NumberInput source="administrativeFee" validate={required()}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
        />

      </SimpleForm>
    </Edit>
  );
};

export default PaymentSettingsEdit;
