import {
  Edit, NumberInput, SimpleForm, TextInput,
  required
} from 'react-admin';
import useErrorHandler from '../../common_modules/hooks/useErrorHandler';
import SaveOnlyToolbar from '../../common_modules/components/SaveOnlyToolbar';
import { BANK_ACCOUNT_TYPE } from '../../common_modules/constants/choices';
import NumericRadioButtonGroupInput from '../../common_modules/components/NumericRadioButtonGroupInput';

export const TransferSettingsEdit = () => {
  const handleError = useErrorHandler();
  return (
    <Edit title="振込設定" mutationMode='pessimistic' mutationOptions={{ onError: handleError }} resource='transfer-settings' id={1} redirect={false}>
      <SimpleForm toolbar={<SaveOnlyToolbar />} sx={{ maxWidth: '600px' }}>
        <TextInput source="remitterCode" fullWidth validate={required()} />
        <TextInput source="remitterName" validate={required()} />
        <TextInput source="bankNumber" fullWidth validate={required()} />
        <TextInput source="bankName" fullWidth validate={required()} />
        <TextInput source="bankBranchNumber" validate={required()} />
        <TextInput source="bankBranchName" validate={required()} />
        <NumericRadioButtonGroupInput source="accountType" choices={BANK_ACCOUNT_TYPE} validate={required()} />
        <TextInput source="accountNumber" validate={required()} />
      </SimpleForm>
    </Edit>

  );
};

export default TransferSettingsEdit;
