import React, { useEffect } from 'react';
import { Datagrid, DatagridProps, useListContext, Labeled, DatagridRow } from 'react-admin';
import { useMediaQuery, Theme, TableBody, TableCell } from '@mui/material';

interface CustomDatagridProps extends DatagridProps {
  // eslint-disable-next-line no-unused-vars
  onTotalChange?: (total: number) => void;
}

const SmallScreenDatagridBody: React.FC<DatagridProps> = (props) => {
  const { data = [], children, ...rest } = props;
  const { selectedIds, onToggleItem } = useListContext(); // リストコンテキストから選択管理機能を取得

  return (
    <TableBody>
      {data.map((record, rowIndex) => (
        <DatagridRow
          key={record.id ?? `row${rowIndex}`}
          id={record.id ?? `row${rowIndex}`}
          record={record}
          selected={selectedIds.includes(record.id)} // 選択されているかどうか
          onToggleItem={() => onToggleItem(record.id)} // 選択トグル機能
          {...(rest as any)}
        >
          <TableCell
            style={{
              display: 'block',
              padding: '16px',
              border: '1px solid #ccc',
              borderRadius: '8px',
              margin: '8px 0'
            }}
          >
            {React.Children.toArray(children).map((field, index) =>
              field && React.isValidElement<any>(field) ? (
                <div key={index} style={{ marginBottom: '8px' }}>
                  <Labeled key={field.props.source}>{field}</Labeled>
                </div>
              ) : null
            )}
          </TableCell>
        </DatagridRow>
      ))}
    </TableBody>
  );
};

const CustomDatagrid: React.FC<CustomDatagridProps> = ({ onTotalChange, children, ...props }) => {
  const { total } = useListContext();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (onTotalChange) {
      onTotalChange(total);
    }
  }, [total, onTotalChange]);

  return (
    <Datagrid
      {...props}
      header={isSmall ? <></> : undefined} // isSmall のときは空の要素を表示
      body={isSmall ? <SmallScreenDatagridBody {...props} /> : undefined}
    >
      {children}
    </Datagrid>
  );
};

export default CustomDatagrid;
