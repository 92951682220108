import React, { ReactNode, useCallback } from 'react';
import { Drawer } from '@mui/material';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';

interface SidebarDrawerProps {
  // eslint-disable-next-line no-unused-vars
  children: ({ id, onCancel }: { id: string; onCancel: () => void }) => ReactNode;
  path: string;
  basePath: string;
}

const SidebarDrawer: React.FC<SidebarDrawerProps> = ({ children, path, basePath }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const match = matchPath(path, location.pathname);

  const handleClose = useCallback(() => {
    navigate(basePath);
  }, [navigate, basePath]);

  return (
    <Drawer
      variant="persistent"
      open={!!match}
      anchor="right"
      onClose={handleClose}
      sx={{ zIndex: 100 }}
    >
      {!!match && children({ id: (match.params as { id: string }).id, onCancel: handleClose })}
    </Drawer>
  );
};

export default SidebarDrawer;
